<template>
	<div id="top">
		<nav class="main-nav fix-on-scroll" :class="{ 'show-mobile-nav': showMobileNav }">
			<div class="row">
				<div class="columns column12">
					<div class="nav-bar">
						<button class="logo" aria-label="Back to homepage" @click="handleLogoClick">
							<div class="logo-container">
								<div class="logo-image default-logo"></div>
								<div class="logo-image fixed-logo"></div>
							</div>
						</button>
						<div class="nav-content">
							<ul>
								<li class="menu-close" @click="hideMobileNav">
									<font-awesome-icon class="icon" icon="fal fa-xmark" size="1x" />
								</li>
								<li v-for="item in topmenu[locale]" :key="item.filename" @click="hideMobileNav">
									<nuxt-link :to="item.filename">
										{{ item.header }}
									</nuxt-link>
								</li>
								<li class="lang-nav mobile-only">
									<div v-for="item in langmenu" :key="item.filename" class="language">
										<nuxt-link
											class="flag"
											:class="item.language"
											:to="item.filename"
											@click="hideMobileNav"
										>
											<span>{{ $t(item.language) }}</span>
										</nuxt-link>
									</div>
								</li>
							</ul>
						</div>
						<div v-if="page" class="language-navigation" @click="toggleLanguageMenu">
							<div v-if="langmenu.length" class="lang-select">
								<span>{{ locale }}</span>
								<font-awesome-icon :icon="`fal fa-chevron-down`" size="1x" />
								<div class="lang-wrapper" :class="{ active: showLanguageMenu }">
									<div v-for="item in langmenu" :key="item.filename" class="language">
										<nuxt-link
											class="flag"
											:class="item.language"
											:to="item.filename"
											@click="hideMobileNav"
										>
											<span>{{ item.language }}</span>
										</nuxt-link>
									</div>
								</div>
							</div>
						</div>
						<div class="buttons">
							<div class="menu-button mobile-only" @click="toggleMobileNav">
								<font-awesome-icon class="icon" icon="fal fa-bars" size="3x" />
							</div>
							<book-button class="button book-now cta reserve-now">
								<font-awesome-icon class="icon" icon="fal fa-calendar-alt" size="1x" />
								<span>{{ defaults[locale].website.book }}</span>
							</book-button>
						</div>
					</div>
				</div>
			</div>
		</nav>
	</div>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { defaults, fetchDefaults } = useDefaults();
const { locale } = useI18n();
const localeRoute = useLocaleRoute();
const route = useRoute();

await fetchPage();
await fetchDefaults();

const showMobileNav = ref(false);
const showLanguageMenu = ref(false);

const { data: topmenu } = await useWebsiteFetch('topmenu');

const langmenu = computed(() => {
	return (page.value && page.value.langmenu) ?? [];
});

const toggleMobileNav = () => {
	showMobileNav.value = !showMobileNav.value;
};

const hideMobileNav = () => {
	showMobileNav.value = false;
};

const toggleLanguageMenu = () => {
	showLanguageMenu.value = !showLanguageMenu.value;
};

const handleLogoClick = () => {
	if (route.name?.startsWith('index')) {
		window.scrollTo(0, 0);
	} else {
		const route = localeRoute('index');
		navigateTo(route.fullPath);
	}
};
</script>

<style lang="scss" scoped>
.mobile-only {
	display: none !important;
}

.main-nav {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1000;
	box-shadow: 0 0 20px rgba(0 0 0 / 10%);
	background: rgb(205 205 205 / 40%);
	backdrop-filter: blur(10px);

	ul li {
		display: inline-block;
		font-family: var(--body-font-family);
		text-align: center;
	}
}

.nav-bar {
	display: flex;
	align-items: center;
	padding: 8px 0;
	z-index: 9;
	position: relative;
	height: 128px;
	transition: all 0.3s ease-in-out;

	.buttons {
		display: flex;
		align-items: center;
		gap: 10px;

		.button {
			height: 40px;
			padding: 0 20px;
			display: flex;
			justify-content: center;
			align-items: center;

			span {
				padding-top: 1px;
			}
		}

		.icon {
			display: none;
			margin-right: 5px;
		}
	}
}

.logo {
	all: unset;
	cursor: pointer;
	padding: 0;
	margin: 18px 0;

	h3 {
		margin: 0 0 5px;
		font-size: 24px;
	}

	img {
		max-width: 50px;
	}
}

.nav-content {
	margin: auto;
	width: 60%;
	display: flex;
	justify-content: center;
	font-size: 18px;

	a {
		color: var(--body-color);
		text-decoration: none;
		margin: 0 10px;
		letter-spacing: 1px;
		font-weight: 400;

		&.router-link-active,
		&:hover {
			color: var(--cta-color-hover);
		}
	}

	.menu-close {
		color: #000;
		display: none;
		font-size: 30px;
		cursor: pointer;
		position: absolute;
		top: 15px;
		width: auto;
		right: 20px;
		padding: 0;
		margin: 0;
	}
}

.lang-nav,
.lang-select {
	.language {
		margin: 20px 0;
		color: red;

		// .flag::before {
		// 	content: '';
		// 	width: 16px;
		// 	position: relative;
		// 	border-radius: 100%;
		// 	height: 16px;
		// 	margin-right: 6px;
		// 	display: inline-block;
		// 	padding: -10px;
		// }

		.flag.en::before {
			background: url('~/assets/images/en.png') no-repeat;
		}

		.flag.nl::before {
			background: url('~/assets/images/nl.png') no-repeat;
		}

		.flag.de::before {
			background: url('~/assets/images/de.png') no-repeat;
		}

		.flag.fr::before {
			background: url('~/assets/images/fr.png') no-repeat;
		}

		.flag.es::before {
			background: url('~/assets/images/es.png') no-repeat;
		}

		.flag.ca::before {
			background: url('~/assets/images/ca.png') no-repeat;
		}
	}

	img {
		width: 16px;
		float: left;
		margin: 0 5px 0 0;
	}

	a {
		width: 100%;
		float: left;
		color: var(--body-color);
		text-decoration: none;
		margin: 4px 0;
		text-transform: uppercase;
		font-family: var(--body-font-family);

		&.active {
			color: var(--cta-color-hover);
		}
	}
}

.language-navigation:hover .lang-nav-sel,
.language-navigation:hover .fa-globe {
	color: var(--cta-color);
}

.lang-nav-sel {
	box-shadow: none;
	cursor: pointer;
	width: 30px;
	padding: 0 4px 0 0;
	color: #fff;
	border: none;
	text-transform: uppercase;
	font-size: 12px;
	font-family: sans-serif;
	appearance: none;
	background: url('~/assets/images/dropdown.png') right 1px center no-repeat;
	background-size: 7px;
	transition: color 0.3s ease-in-out;

	option {
		color: var(--body-color);
	}

	&::-ms-expand {
		display: none;
	}
}

.lang-select {
	height: 40px;
	margin-right: 10px;
	background: rgba(255 255 255 / 80%);
	padding: 13px 10px;
	position: relative;
	cursor: pointer;
	text-transform: uppercase;
	font-size: 13px;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;

	span {
		margin: 0 4px;
		padding-top: 3px;
	}

	a {
		color: #000;
		display: flex;
		flex-flow: row nowrap;
		gap: 8px;
		margin: 7px 0;
		place-content: center flex-start;
		width: 100%;
		text-decoration: none;
	}

	svg {
		font-size: 12px;
	}

	.language {
		margin: 0;

		a.flag {
			margin: 2px 0;
			flex-flow: row nowrap;
			place-content: center flex-start;
			gap: 2px;
		}
	}
}

.lang-wrapper {
	display: none;
	position: absolute;
	top: 35px;
	left: 3px;
	right: 3px;
	padding: 10px 13px;
	text-align: center;
	background: var(--primary-background-color);
	box-shadow: 0 18px 18px rgb(0 0 0 / 10%);
	z-index: 90;

	&.active,
	a {
		display: flex;
		flex-flow: column nowrap;
		place-content: space-between center;
		color: #000 !important;
		font-size: 13px;
		gap: 5px;
	}

	a {
		width: 100%;
		flex-direction: row;
	}

	img {
		width: 16px;
		height: 16px;
		margin: 2px 0 0;
	}
}

.language-navigation .fa-globe {
	margin: 0 2px 0 0;
	color: var(--body-color);
	transition: color 0.3s ease-in-out;
}

.fix-on-scroll {
	transition:
		background-color 0.5s ease,
		backdrop-filter 0.5s ease;
}

.logo-container {
	position: relative;
	width: 100px;
	height: 100px;
}

.logo-image {
	position: absolute;
	top: 10;
	left: 10;
	width: 100%;
	height: 100%;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	transition: all 0.3s ease-in-out;
}

/* Default logo styling */
.default-logo {
	background-image: url('~/assets/images/logo-mu-hotel-spa-andorra.svg');
	opacity: 1;
	transform: scale(1);
}

.fixed-logo {
	background-image: url('~/assets/images/logo-reduced.svg');
	opacity: 0;
	transform: scale(0.7);
}

.fix-on-scroll.fixed {
	.nav-bar {
		height: 96px;
	}
}

.fix-on-scroll.fixed .default-logo {
	opacity: 0;
	transform: scale(1);
}

.fix-on-scroll.fixed .fixed-logo {
	opacity: 1;
	transform: scale(0.65);
}

@media (max-width: 1200px) {
	ul {
		background: rgba(205 205 205 / 50%) !important;
		backdrop-filter: blur(10px);
	}

	.nav-bar a {
		font-size: 14px;
	}

	.nav-bar .buttons {
		span {
			display: none;
		}

		.icon {
			margin-right: 0;
			font-size: 22px;
			display: unset;
		}
	}

	.menu-button {
		text-transform: uppercase;
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: space-between;
		margin: 0 10px 0 0;
		cursor: pointer;

		svg {
			font-size: 22px;
		}

		span {
			margin: 0 0 0 5px;
		}

		.icon {
			font-size: 30px;
		}
	}

	.mobile-only {
		display: flex !important;

		span {
			text-transform: initial;
		}

		&.lang-nav {
			display: inline-block !important;
			margin: 100px 0 0;
			padding: 0 10px;
		}
	}

	.lang-nav {
		.language {
			.flag::before {
				content: '';
				width: 16px;
				position: relative;
				border-radius: 100%;
				height: 16px;
				margin: 2px 6px -2px 0;
				display: inline-block;
				padding: -10px;
			}
		}
	}

	.language-navigation {
		display: none;
	}

	.main-nav {
		position: fixed !important;
		top: 0;
		box-shadow: 0 0 10px rgb(0 0 0 / 10%);

		ul li {
			font-family: var(--heading-font-family);
		}

		&.show-mobile-nav {
			height: 100vh;

			.nav-content ul {
				height: 100vh;
				display: flex;
				flex-direction: column;
				justify-content: center;
				opacity: 1;
				visibility: visible;
				transition: opacity 0.3s ease-in-out;

				li:not(.menu-close) {
					width: 100%;
				}

				li:not(.lang-nav, .menu-close) {
					margin: 7px 0;

					a {
						font-size: 26px;
					}
				}
			}
		}

		ul {
			position: fixed;
			inset: 0;
			background: var(--primary-background-color);
			text-align: left;
			padding: 20px;
			opacity: 0;
			visibility: hidden;
			overflow-y: scroll;
		}
	}

	.nav-content .menu-close {
		display: block;
	}

	.logo {
		h3 {
			font-size: 40px;
		}
	}
}

@media (max-width: 880px) {
	.nav-bar .buttons {
		.button {
			padding: 16px;
		}

		.menu-button {
			margin-right: 5px;

			.icon {
				font-size: 30px;
			}
		}

		.icon {
			margin-right: 0;
			font-size: 22px;
		}
	}
}

@media (max-width: 580px) {
	.nav-bar .buttons span {
		display: none;
	}

	.nav-bar .logo {
		font-size: 22px;
	}
}
</style>
