<template>
	<div class="footers">
		<footer class="main-footer">
			<div class="row">
				<div class="columns column3">
					<div class="footer-logo">
						<img
							src="~/assets/images/logo-mu-hotel-spa-andorra.svg"
							loading="lazy"
							alt="Hotel Mu Hotel & Spa Andorra"
						/>
					</div>
				</div>
				<div class="columns column3">
					<h4>{{ defaults[locale].website.hotelName }}</h4>
					<p class="address">
						<span>{{ defaults[locale].website.street }} {{ defaults[locale].website.number }}</span>
						<span>{{ defaults[locale].website.postalCode }} {{ defaults[locale].website.city }}</span>
						<span>{{ defaults[locale].website.country }}</span>
					</p>
				</div>
				<div class="columns column3">
					<h4>{{ $t('contact') }}</h4>
					<p>
						<a :href="`tel:${$phoneRaw(defaults[locale].website.phone)}`">
							<span>{{ defaults[locale].website.phone }}</span>
						</a>
						<a :href="`mailto:${defaults[locale].website.email}`">
							<span>{{ defaults[locale].website.email }}</span>
						</a>
					</p>
					<social-media :socials="socials" />
				</div>
				<div class="columns column3">
					<h4>{{ $t('quickLinks') }}</h4>
					<p>
						<nuxt-link v-for="item in footermenu[locale]" :key="item.filename" :to="item.filename">
							{{ item.header }}
						</nuxt-link>
					</p>
				</div>
			</div>
		</footer>
		<footer class="author-footer">
			<div class="row">
				<div class="columns column12">
					<div class="border" />
					<div class="bottomfooter-row">
						<p class="align-left">
							<a
								href="https://www.porterforhotels.com/hotel-websites"
								target="_blank"
								rel="noopener noreferrer"
								title="Hotel website design by Porter"
							>
								Hotel Website Design
							</a>
							by:
							<a href="https://porterforhotels.com" target="_blank">
								<img class="siteByLogo" :src="$t('siteByLogo')" loading="lazy" alt="" />
								Porter</a
							>
						</p>
						<div class="menu align-right">
							<span v-for="(item, idx) in bottomfooter[locale]" :key="item.filename">
								<nuxt-link :to="item.filename">
									{{ item.header }}
								</nuxt-link>
								<span v-if="idx < bottomfooter[locale].length - 1">|</span>
							</span>
						</div>
					</div>
				</div>
			</div>
		</footer>
	</div>
</template>

<script setup>
const { data: footer } = await useWebsiteFetch('footermenu');
const { footermenu, bottomfooter } = footer.value;
const { locale } = useI18n();

const { defaults, socials, fetchDefaults } = useDefaults();

await fetchDefaults();
</script>

<style lang="scss" scoped>
.footers {
	color: var(--footer-color);
	background: var(--footer-background-color);

	a,
	span {
		color: var(--footer-color);
		text-decoration: none;
		transition: all 0.3s ease-in-out;
	}

	a:hover {
		text-decoration: underline;
		transition: all 0.3s ease-in-out;
	}

	h3,
	h4 {
		color: var(--footer-color);
		letter-spacing: 1px;
	}

	h4 {
		font-size: 1.3em;
	}
}

.main-footer {
	p {
		font-size: 0.9em;
	}

	a,
	span {
		display: block;
	}

	.footer-logo {
		max-width: 180px;
		padding: 0;
		text-decoration: none;
		margin: 18px 0;

		h3 {
			margin: 0 0 5px;
			font-size: 24px;
		}

		img {
			max-width: 117px;
		}
	}
}

.bottomfooter-row {
	display: flex;
	align-items: left;

	.align-center {
		width: 100%;
	}

	.menu {
		margin-left: auto;
		display: flex;
		align-items: center;
	}
}

.author-footer {
	font-size: 0.8em;
	padding: 5px 0;
	text-align: center;
	width: 100%;

	.border {
		background: #333;
		width: 100%;
		height: 1px;
	}

	img {
		max-width: 20px;
		aspect-ratio: 2 / 3;
		margin: 0 1px 0 2px;
	}
}

@media (max-width: 1080px) {
	footer .columns {
		margin: 15px auto;
	}
}

@media (max-width: 580px) {
	footer {
		text-align: center;
	}

	.main-footer {
		padding: 75px 0 50px;

		.footer-logo {
			margin: 0 auto 20px;
		}
	}

	.bottomfooter-row {
		flex-flow: column-reverse wrap;
		align-items: center;

		.menu {
			margin: 20px auto;
		}
	}
}
</style>
